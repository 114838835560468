import React from "react"

/*** COMPONENTS ***/
import Main from "../../components/main"
import SEO from "../../components/seo"
import "../../styles/project.css"
import vrstrandedHeader from "../../images/VR-stranded.png"
import image1VRStranded from "../../images/image1-vr-stranded.png"

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes"

const VRStrandedPage = () => (
  <Main>
    <SEO title="VR Stranded" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-vrstranded" src={vrstrandedHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">VR STRANDED</p>
          <p class="project-header-text-grey">BACHELOR'S PROJECT</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; C#</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-10-22</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          My Bachelor's Project was a <b>Virtual Reality</b> (VR) multiplayer
          game for smartphones done together with Erik Åkesson, Felix Willebrand
          Westin, Mattias Almlöf, Nils Andersson and Otto Ortega.
          <br />
          <br />
          The project was created in Unity with the help of <b>Vuforia</b>,{" "}
          <b>Photon Unity Network Framework</b> and <b>Google Cardboard</b>.
          During this project, we worked according to the <b>Scrum</b> agile
          framework. There was a lot of focus on the position tracking from the
          players head, translated to VR. The resulting game is playable on
          Android devices. The game keeps track of position, rotation and
          tracking with the help of AR techniques. The final game has network
          functionality and supports up to four players.
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-41">
        <img alt="Image1-vrstranded" src={image1VRStranded} />
      </div>
      <div class="project-grid-item-51">
        <p class="project-text-body-dark">Pictures from the game.</p>
      </div>

      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
)

export default VRStrandedPage
